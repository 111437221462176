<template>
  <div>
    <page-loading :show="loading" />
    <v-row>
      <v-col cols="6">
        <div class="d-flex flex-wrap">
          <lead-source-form style="width: 100%"></lead-source-form>
          <client-form :client="client" style="width: 100%"></client-form>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="d-flex flex-wrap">
          <lead-status style="width: 100%"></lead-status>
          <assigned-agent-form
            :assignedAgent="assignedAgent"
            style="width: 100%"
          ></assigned-agent-form>
        </div>
      </v-col>
    </v-row>
    <modal-client></modal-client>
    <modal-agent></modal-agent>
  </div>
</template>

<script>
import { mapState } from 'vuex';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const LeadSourceForm = () =>
  import('@/components/transaction-management/lead/form/lead-source-form');
const LeadStatus = () => import('@/components/transaction-management/lead/partials/lead-status');
const ClientForm = () => import('@/components/transaction-management/lead/form/client-form');
const AssignedAgentForm = () =>
  import('@/components/transaction-management/lead/form/assigned-agent-form');
const ModalClient = () => import('@/components/modals/modal-client');
const ModalAgent = () => import('@/components/modals/modal-agent');

export default {
  name: 'unit-type-form',
  components: {
    PageLoading,
    LeadSourceForm,
    LeadStatus,
    ClientForm,
    AssignedAgentForm,
    ModalClient,
    ModalAgent,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState({
      lead: (state) => state.lead.form,
      formType: (state) => state.lead.form.formType,
      client: (state) => state.lead.form.client,
      assignedAgent: (state) => state.lead.form.assignedAgent,
    }),
    loading: {
      get() {
        return this.$store.state.lead.form.loading;
      },
      set(value) {
        this.$store.commit('lead/form/SET_LOADING', value);
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>
